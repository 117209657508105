import React from 'react';
import Page from '../../components/Page';
import get from 'lodash/get';
import './series.scss';
import rehypeReact from 'rehype-react';
import Grid from '../../components/Grid';
import Footer from '../../components/Footer';
import SmallHeader from '../../components/SmallHeader';
import Chip from '../../components/Chip';
import ArticleList from '../../components/ArticleList';
import { ImageCaption } from '../../components/ImageCaption';
import { graphql } from 'gatsby';

function ArticlesComponent(articles) {
  if (articles !== null) {
    return <ArticleList>{articles}</ArticleList>;
  }
}

class SeriesTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const postInSeries = this.props.data.allMarkdownRemark;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        'image-caption': ImageCaption,
      },
    }).Compiler;

    return (
      <Page title={`${post.frontmatter.title} | ${siteTitle}`}>
        <Grid>
          <div className="c-individual-post">
            <SmallHeader />
            <h1>{post.frontmatter.title}</h1>
            <Chip chipUrl={post.frontmatter.topicurl}>Topic: {post.frontmatter.topicname}</Chip>
            {renderAst(post.htmlAst)}
            {ArticlesComponent(postInSeries)}
          </div>
          <Footer />
        </Grid>
      </Page>
    );
  }
}

export default SeriesTemplate;

export const query = graphql`
  query($slug: String!, $seriesname: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        summary
        posttype
        topicname
        seriesname
        topicurl
        seriesurl
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          seriesname: { eq: $seriesname }
          posttype: { eq: "article" }
          published: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            posttype
            topicname
            seriesname
          }
        }
      }
    }
  }
`;
